import React from "react"
import BlockWrapper from "../technical/BlockWrapper"
import HeadlineEnhanced from "../molecules/HeadlineEnhanced"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"

import Slider from "react-slick"
import { ArrowLeft16, ArrowRight16 } from "@carbon/icons-react"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

const ProductPromoSliderBlock = ({ block }) => {
  function NextArrow(props) {
    const { className, onClick } = props
    return (
      <span className={className} onClick={onClick}>
        <span>
          <ArrowRight16 />
        </span>
      </span>
    )
  }

  function PrevArrow(props) {
    const { className, onClick } = props
    return (
      <span className={className} onClick={onClick}>
        <span>
          <ArrowLeft16 />
        </span>
      </span>
    )
  }

  const settings = {
    slidesToShow: 2,
    slidesToScroll: 2,
    dots: true,
    arrows: true,
    mobileFirst: true,
    infinite: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    appendDots: (dots) => (
      <div>
        <ul> {dots} </ul>
      </div>
    ),
    customPaging: (i) => <button page={i + 1}></button>,

    responsive: [
      {
        breakpoint: 9999,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <BlockWrapper
      className="bg-gray-50"
      block={block}
      showHeadline="false"
      blockPadding="py-8 md:py-16"
    >
      <div className="flex flex-row flex-wrap -mx-grid">
        <div className="w-full mb-8 md:w-1/2 lg:w-2/5 px-grid md:mb-0">
          <HeadlineEnhanced
            kicker={block.kicker}
            headline={block.headline}
            headlineLevel={block.headline_level}
            subheadline={block.subheadline}
          />
          <div className={block.headline?.length > 0 ? "pt-8" : ""}>
            <Richtext text={block.text} />
          </div>
        </div>
        <div className="w-full md:w-1/2 lg:w-3/5 px-grid">
          <Slider className="promo-slider -mx-grid" {...settings}>
            {block.promo_items?.length > 0 &&
              block.promo_items.map((item, index) => (
                <div className="px-grid">
                  {item.image?.filename?.length > 0 && (
                    <Link
                      className="relative block bg-white group"
                      link={item.link}
                    >
                      <Image
                        className="block w-full h-full"
                        image={item.image}
                        fitIn={true}
                        forceLoad={true}
                        aspectRatio="3by4"
                      />
                      {item.headline?.length > 0 && (
                        <div className="absolute inset-x-0 bottom-0 px-4 py-4 font-bold transition-opacity duration-500 bg-gradient-to-t from-gray-200 lg:opacity-0 lg:group-hover:opacity-100">
                          {item.headline}
                        </div>
                      )}
                    </Link>
                  )}
                </div>
              ))}
          </Slider>
        </div>
      </div>
    </BlockWrapper>
  )
}

export default ProductPromoSliderBlock
